import dayjs from "dayjs"
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)
dayjs.locale('zh-cn');
//v 一般是"YYYY-MM"格式
export const getMonthBeginEnd = (v=null) => {
    let begin, end
    if (v){
         begin = dayjs(v).startOf('month').format(TimeFormat.FULL0000)
         end = dayjs(v).endOf('month').format(TimeFormat.YMD2359)  //由于日期总是得到的那一天的0:00，所以end需要再往前一天
    } else {
         begin = dayjs().startOf('month').format(TimeFormat.FULL0000)
         end = dayjs().endOf('month').format(TimeFormat.YMD2359)  //由于日期总是得到的那一天的0:00，所以end需要再往前一天
    }
    return [begin, end]
}

//现在v是dayjs
export const getDayJSMonthBeginEnd = (v) => {
    let begin = v.startOf('month')
    let end = v.endOf('month').add(1, "day")  //由于日期总是得到的那一天的0:00，所以end需要再往前一天
    return [begin, end]
}


export const getWeekBeginEnd = (v=null) => {
    let begin, end
    //week非常坑，是日-六， 所以都要加1
    if (v){
        begin = dayjs().startOf('week')
        end = dayjs().endOf('week').add(1, "day")
    } else {
        begin = dayjs().startOf('week')
        end = dayjs().endOf('week').add(1, "day")
    }
    return [begin, end]
}

/**
 * 这个方法很细节，因为存在月初不是周一的情况，以及月末不是周末的情况，antdv传来的都是周一和周末，所以要做判断。
 */
export const getDayJSWeekBeginEnd = v => {
    let monthBegin = v.startOf("month")
    let begin = v.startOf("week")
    // if (begin.isSameOrBefore(monthBegin)){
    //     begin = monthBegin //取消注释会导致周第一天比月第一天早时，不显示那些先于月第一天的。
    // }

    let monthEnd = v.endOf("month")
    let end = v.endOf('week')
    if (monthEnd.isSameOrBefore(end)){
        //那么周最后一天就是月末
        end = monthEnd
    }

    begin = begin.add(1, "day")
    end = end.add(1, "day") //这里加一天，是因为后台数据库日期时间默认是 0:00:00，懒得给23:59:59，所以加一天算了。

    return [begin, end]
}

export const getMonthEndNumber = () => {
    return  dayjs().endOf('month').format("MM")
}

export const getDateOfDay = v => {
    return dayjs(v).date()
}

//得到today所在的周的第一天和上一周的第一天
export const getWeekTime = today => {
    today = dayjs(today)
    let start_time = today.startOf("week").format(TimeFormat.YMD)
    let end_time = today.endOf("week").format(TimeFormat.YMD2359)
    let start_end_time = `${start_time}-${end_time}`
    return {
        start_time,
        end_time,
        start_end_time,
    }
}

export class TimeFormat{
    static YMD = "YYYY-MM-DD"
    static YMD2359 = "YYYY-MM-DD 23:59:59"
    static YMDH = "YYYY-MM-DD HH"
    static YMDHm = "YYYY-MM-DD HH:mm"
    static FULL = "YYYY-MM-DD HH:mm:ss"
    static FULL0000 = "YYYY-MM-DD 00:00:00"
    static DAY_CHINESE = "YYYY年MM月DD日"
    static DAY_CHINESE_MD = "MM月DD日"
    static FULL_CHINESE = "YYYY年MM月DD日 HH时mm分ss秒"
    static HM = "HH:mm"
}
import axios from "@/utils/http";
import {isArray} from "lodash-es";

const picMap = (arr, name) => {
    if ((!arr || arr.length === 0)) {
        return []
    } else {
        if (!isArray(arr)){
            arr = [arr]
        }
    }
    return arr.map(item => picMapCB(item, name))
}

const picMapCB = (item, name) =>{
    return  {
        uid: item.file_id,
        name,
        url: axios.host  + item.file_path,
        thumbUrl: axios.host  + item.file_path,
        file_path: item.file_path,
        file_id: item.file_id,
    }
}

const picMapPath = (item) => {
    return {
        file_id: item.file_id,
        file_path: item.file_path,
        name: item.name
    }
}

export {
    picMap, picMapCB, picMapPath
}
<template>
  <a-config-provider :getPopupContainer="getPopupContainer" :locale="zhCN">
    <div class="app">
      <Login v-if="loginFlag === 0"/>

      <a-layout v-else-if="loginFlag === 1" style="min-height: 100vh">
        <a-layout-sider v-model:collapsed="collapsed" collapsible>
          <div class="logo" />
          <a-menu v-model:selectedKeys="routeState.key"
                  v-model:openKeys="openKeys"
                  theme="dark" mode="inline"
                  @openChange="onOpenChange">
            <a-sub-menu :key="item.key" v-for="(item, key) of routes">
              <template #title>
            <span>
              <component :is="$antIcons[item.meta.icon]" />
              <span>{{item.meta.title}}</span>
            </span>
              </template>
              <a-menu-item :key="subItem.key" v-for="subItem of item.children"
                           @click="gogogo(item, subItem)"
              >{{subItem.meta.title}}</a-menu-item>
            </a-sub-menu>
          </a-menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header style="background: #fff; padding: 0" >
            <a-row justify="end">
              <a-col :xs="10" :sm="8" :md="6" :lg="4" :xl="6">欢迎，<b>{{adminTitle}} :{{adminInfo.admin_name}}</b>！                <a-badge :count="card_req_cnt">
                <div class="alarm"  @click="goCardReq">
                </div>
              </a-badge></a-col>
              <a-col :xs="12" :sm="10" :md="8" :lg="4" :xl="3">
                <a-dropdown placement="bottom">
                  <a-button size="small" type="text">账号管理</a-button>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <a target="_blank" @click="goChangePass">
                          修改密码
                        </a>
                      </a-menu-item>
                      <a-menu-item>
                  <span  @click="changePhone">
                    修改手机
                  </span>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown> &nbsp;
                <a-popconfirm title="确定注销？"
                              @confirm="logout"
                              ok-text="确定" cancel-text="取消">
                  <template #icon><question-circle-outlined style="color: red"/></template>
                  <a-button size="small" type="text">注销</a-button>
                </a-popconfirm>
              </a-col>
            </a-row>
          </a-layout-header>
          <a-layout-content style="margin: 0 16px">
            <a-breadcrumb style="margin: 16px 0">
              <a-breadcrumb-item :key="routeState.name">首页</a-breadcrumb-item>
              <a-breadcrumb-item :key="routeState.name"
                                 :to="routeState.path"
              >{{routeState.name}}</a-breadcrumb-item>
            </a-breadcrumb>
            <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
              <router-view></router-view>
            </div>
          </a-layout-content>
          <a-layout-footer style="text-align: center">
          </a-layout-footer>
        </a-layout>
      </a-layout>
    </div>
  </a-config-provider>
</template>

<script>
import {UserOutlined, GoldFilled} from "@ant-design/icons-vue";
import {defineComponent, ref, reactive, computed, onMounted, watch} from 'vue'
import {expRoutes} from './router'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import gs from "good-storage"
import Login from "@/views/Login/Login.vue";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import axios from "@/utils/http";
import {AdminTitle} from "@/router"
import GS from "@/utils/gsSave";
import {isEmpty} from "lodash-es";
dayjs.locale('zh-cn');

export default defineComponent({
  components:{
    UserOutlined, GoldFilled, Login
  },
  data() {
    return {
      collapsed: ref(false),
      routes: reactive(expRoutes()),
      zhCN,
    };
  },
  methods: {
    getPopupContainer(el, dialogContext) {
      if (dialogContext) {
        return dialogContext.getDialogWrap();
      } else {
        return document.body;
      }
    },
  },
  setup(){
    let router = useRouter()
    let store = useStore()
    let card_req_cnt = ref(0)
    let routeState = computed(() => store.getters["bread/routeState"])
    let adminInfo = computed(()=>store.getters["organ/adminInfo"])
    let loginFlag = computed(() => store.getters["organ/loginFlag"])

    let adminTitle = computed(() => {
      for (const title of Object.values(AdminTitle)) {
        if (title.code === adminInfo.value.admin_type){
          return title.label
        }
      }
    })

    const gogogo = (item, subItem) => {
      let path = `${item.path}/${subItem.path}`
      router.push(path)
    }

    const goChangePass = () => {
      router.push({
        name: "change_pass"
      })
    }

    onMounted(() => {
      //以下是每次打开页面都要做的
      console.log("每次刷新/打开页面都要做：判断是否过期")
      //1. 初始化工作
      GS.setUserPage(1)

      //2. 检查登录
      if (loginFlag.value === 0){ //因为当loginFlag === 1以登录方式进来时，也会触发下面，导致有些数据丢失
        let adminInfo = gs.get("__admin__", {})
        if(!isEmpty(adminInfo)) {
          store.dispatch("organ/checkExpires", adminInfo)
          if (loginFlag.value === 1){
            getCardReqCnt(adminInfo.admin_id)
          }
        }
      }
      //剩下的情况就是第一次打开。当然没有
      watch(() => routeState.value.name, newV => {
        console.log(newV)
      })
    })

    const changePhone = async () => {

    }

    const logout = () => {
      store.dispatch("organ/logout")
    }

    const goCardReq = () => {
      router.push({name: 'card_req'})
    }

    const getCardReqCnt = (admin_id) => {
      axios.post("card_reqs/cnt", {
        condition: {admin_id}
      }).then(res=>{
          card_req_cnt.value = res.data.cnt.length
      })
    }

    const openKeys = computed(() => store.getters["bread/openKeys"])
    
    const onOpenChange = v =>{
      let val
      switch (v.length) {
        case 0:
          val = []
          break
        case 1:
          val = [v[0]]
          break
        case 2:
          val = [v[1]]
          break
      }
      store.commit("bread/setOpenKeys", [v[1]])
    }

    return {gogogo, adminInfo, changePhone, goCardReq, card_req_cnt, routeState,onOpenChange, openKeys,
      loginFlag, logout, goChangePass, adminTitle}
  }
});
</script>

<style lang="stylus">
#components-layout-demo-side .logo
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);

.site-layout .site-layout-background
  background: #fff;

[data-theme='dark'] .site-layout .site-layout-background
  background: #141414;

.manage, .logout
  cursor pointer
  padding 2px 6px
  &:hover
    color #fff
    background #87ceeb

.alarm
  height 25px
  width 25px
  margin-bottom -5px
  cursor pointer
  background url("~@/assets/icons/alarm.png") no-repeat
  background-size 100% 100%
  &:hover
    background-color F8DFDA
    border-radius 50%
    padding 15px
  &:active
    background-color #ddd
    border-radius 50%
    padding 15px
</style>

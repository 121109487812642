<script setup>
import {useStore} from "vuex";
import {reactive, ref} from "vue";
import {load} from "@/utils/loading";
import axios from "@/utils/http";
import {message} from "ant-design-vue";
import SelectAStudio from "@/components/selectComp/SingleSelect/SelectSingleStudio.vue";

let store = useStore()
let loginState = reactive({
  model: {
    admin_phone: "",
    code: "",
    admin_pwd: "",
    login_type: "pwd"
  }
})
let labelCol = { style: { width: '80px' }}
let activeKey = ref(2)
let showFlag = ref(false)

let adminState = reactive({
  companies: [],
  admin_type: 1,
  admin_id: null,
  company_id: null,
  studio_id: null,
})

//这个login只是验证账号密码并返回公司
//fixme 为了防止爆破，三次后出现拉框
const login = (e) => {
  if (e && e.keyCode === 13){
    e.preventDefault();
    // return false; 有效
  }
  load.show("登录中……")
  axios.post("/admins/login", loginState.model).then(res => {
    if (res.data.code === 10000){
      //这里应该改成，选择工作室，选完以后再dispatch
      store.commit("organ/setAdminType", res.data.admin_type)
      showFlag.value = true
      adminState.companies = res.data.admin.companies
      adminState.admin_id = res.data.admin.admin_id
      adminState.admin_type = res.data.admin_type
    }
    load.hide()
  })
}

//todo 这个方法是在login后，选择了具体的工作室，然后再次带着company_id、studio_id和admin_id去拿dict_ids等信息的jwt
const loginHandle = () => {
  if (!adminState.studio_id){
    return message.warn("请选择一个工作室")
  }

  let url_login2 = "/admins/login2"

  axios.post(url_login2, {
    admin_id:adminState.admin_id,
    admin_type: adminState.admin_type,
    company_id:adminState.company_id,
    studio_id: adminState.studio_id
  }).then(res => {
    if(res.data.code === 10000){
      store.dispatch("organ/dispatchAdminInfo", res.data.token)
    }
  })
}

const tabChange = (v) => {
  for(let key in loginState.model){
    loginState.model[key] = ""
  }

  if (v === 1){
    loginState.model.login_type = "code"
  } else if(v===2){
    loginState.model.login_type = "pwd"
  }

}

const selectedStudio = ({company_id, studio_id}) => {
  adminState.studio_id = studio_id
  adminState.company_id = company_id
}
</script>

<template>
  <div id="page" class="site">
    <div class="container">
      <div class="login" @keydown.enter="login">
        <div class="hero">
          <h1 style="font-weight: 700;font-size:46px">你好 SPEC<br>开始一天的工作吧</h1>
          <!--                <p>如果你没有账号<br>可以<a href="#">点击这里</a>进行注册.</p>-->
        </div>
        <div class="main">
          <form action="" @submit.prevent>
            <p>
              <input placeholder="手机号码" v-model="loginState.model.admin_phone">
            </p>
            <p class="password">
              <input type="password" placeholder="密码" v-model="loginState.model.admin_pwd">
              <i class="ri-eye-off-line"></i>
              <a href="#">找回密码</a>
            </p>
            <p>
              <input type="submit" class="submit" value="登录" @click="login">
            </p>
          </form>
          <div class="options">
            <div class="separator">
              <p>使用其他方式登录</p>
            </div>
            <ul>
              <li><a href="#"><i class="ri-account-circle-fill ri-2x"></i></a></li>
              <li><a href="#"><i class="ri-wechat-fill ri-2x"></i></a></li>
              <li><a href="#"><i class="ri-qq-fill ri-2x"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model:visible="showFlag"
             @ok="loginHandle">
      <div class="studio-item">
        <SelectAStudio :studio-companies="adminState.companies" :admin-type="adminState.admin_type"
                       :admin-id="adminState.admin_id" @selectedStudio="selectedStudio"/>
      </div>
    </a-modal>
  </div>
</template>

<style scoped lang="stylus">
@import "@/assets/css/style.css"

</style>
let state = {
    curActivity: {},
    activityId: null
}

let getters = {
    curActivity:(state) => state.curActivity,
    activityId:(state) => state.activityId,
}

let mutations = {
    setCurActivity(state, payload){
        state.curActivity = payload
        state.activityId = payload.activity_id
    },
    setActivityId(state, payload){
        state.activityId = payload
    }
}

let actions = {

}

export default {
    namespaced: true,
    state, getters, actions, mutations
}
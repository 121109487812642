import axios from "@/utils/http";
import http from "@/utils/http";

const state = {
    discnt_id: null,
    discnt: {

    },
    studios: {

    },
    discntMustLabels: {

    },
    discntNotMustLabels: {

    },
    publishData: [],
    publishCnt: 0,
    discntDetailState: {
        info: {
            type: {
                label: ""
            },
            piece_discnt_strategy:{
                label: "",
                desc: ""
            },
            expires_strategy:{
                label: "",
                desc: ""
            },
            name:""
        },
        labels: [],
        studios: [],
    },
    showlabelFlag: false
}

const getters = {
    discnt_id(state){ return state.discnt_id},
    discnt(state){ return state.discnt},
    studios(state){ return state.studios},
    discntMustLabels(state){ return state.discntMustLabels},
    discntNotMustLabels(state){ return state.discntNotMustLabels},
    publishData(state){ return state.publishData},
    publishCnt(state){ return state.publishCnt},
    discntDetailState: state => state.discntDetailState,
    showlabelFlag: state => state.showlabelFlag,
}

const mutations = {
    setDiscntId(state, payload) {
        state.discnt_id = payload
    },
    setDiscnt(state, payload){
        state.discnt = payload
    },
    setStudios(state, payload){
        state.studios = payload
    },
    setDiscntMustLabels(state, payload){
        state.discntMustLabels = payload
    },
    setDiscntNotMustLabels(state, payload){
        state.discntNotMustLabels = payload
    },
    setPublishData(state, payload){
        state.publishData = payload
    },
    setPublishCnt(state, cnt){
        state.publishCnt = cnt
    },
    setDiscntDetailState(state, payload) {
        state.discntDetailState = payload
    },
    setShowlabelFlag(state, payload){
        state.showlabelFlag = payload
    }
}

const actions = {
    //详情页整个的
    reqDiscntDetailWhole({commit}, {discnt_id}){
        let url = `/trade/discounts/v2/detail`
        let discntState = {
            info: {
                type: {
                    label: ""
                },
                piece_discnt_strategy:{
                    label: "",
                    desc: ""
                },
                expires_strategy:{
                    label: "",
                    desc: ""
                },
                name: ""
            },
            labels: [],
            studios: [],
        }
        http.post(url, {discnt_id}).then(res => {
            res = res.data
            if (res.code === 10000){
                discntState.info = res.data
                discntState.info.discnt_id = discnt_id
                discntState.labels_must = res.labels_must.map(label => {
                    label.discnt_id = discnt_id
                    return label
                })
                discntState.labels_not_must = res.labels_not_must.map(label => {
                    label.discnt_id = discnt_id
                    return label
                })
                discntState.studios = res.studios

                commit("setDiscntDetailState", discntState)
                commit("setDiscntMustLabels", discntState.labels_must)
                commit("setDiscntNotMustLabels", discntState.labels_not_must)
                commit("setShowlabelFlag", true)
            }
        })
    },
    /**
     * 详情页的下方发放表格（不包含上方）
     * @param commit
     * @param discnt_id
     * @param current 当前页数
     */
    reqPublishPageData({commit}, {discnt_id, current}){
        let url = `/trade/discounts/v2`
        axios.post(url+"/publish_data", {
            discnt_id,
            page: current
        }).then(res => {
            res = res.data
            if (res.code === 10000){
                commit("setPublishData", res.publish_data.map((item, key) => {
                    item.key = key
                    return item
                }))
                commit("setPublishCnt",res.cnt)
            }
        })
    }
}

export default {
    namespaced: true,
    state, getters, actions, mutations
}
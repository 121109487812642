import {createStore} from "vuex";

import organ from './organ'
import components from './components'
import monthlesson from './monthlesson'
import goods from './goods'
import bread from './bread'
import personal from './personal'
import editor from './components/editor'
import activity from './activity'
import navigator from './navigator'
import system from "@/store/system";
import discnt from "./goods/discnt";
import order from "./goods/order";

export default createStore({
    modules:{
        organ,
        components, editor,
        monthlesson,
        goods,
        bread,
        personal,
        activity,
        system,
        navigator,
        discnt,order
    },
})

module.exports = {
    host: process.env.NODE_ENV === "development" ? "http://localhost:3000/" :"https://spec.space-net.cn/",
    // host: process.env.NODE_ENV === "development" ?  "https://spec.space-net.cn/": "http://localhost:3000/",
    //与后台对应，填入key即可获取name
    DICT_KEYS: [
        "老师标签",
        "课程标签",
        "用户标签",
        "舞种",
        "课程类型",
        "课程难度",
        "卡类型",
        "优惠券类型",
    ]
}

import {host} from "@/utils/params";

const state = {
    content: "",
    imageList1: [],
    imageList2: [],
}

const getters = {
    content(state){ return state.content},
    imageList1(state){ return state.imageList1},
    imageList2(state){ return state.imageList2},
}

const mutations = {
   setContent(state, content){
       state.content = content
   },
   resetImageList(state){
       state.imageList1 = []
       state.imageList2 = []
   },
    pushImageList1(state, list){
       state.imageList1.push(list.replace(host, ""))
    },
    setImageList2(state, list){
       state.imageList2 = list.map(l => l.src.replace(host, ""))
    }

}

const actions = {

}

export default {
    namespaced: true,
    state, getters, actions, mutations
}

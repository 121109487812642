import dayjs from "dayjs";
import {TimeFormat} from "@/utils/time";
import {useRouter} from "vue-router";
const router = useRouter()

const state = {

}

const getters = {

}

const mutations = {

}

const actions = {
    navToGoodsBindStudio({}, {goods_id}){
        router.push({
            name: "goods_bind_studio",
            params: {
                goods_id
            }
        })
    },
}

export default {
    namespaced: true,
    state, getters, actions, mutations
}

<template>
<!--用于管理模块，单一studio选择-->
<!--另一个是多studio选择，用于统计模块-->
  <p style="font-weight: bold">选择公司</p>
  <a-select
    show-search
    style="width: 100%"
    label-in-value
    :options="studioCompanies"
    :value="curCompany.model"
    @change="selectCompany">
  </a-select>
  <p style="margin-top:20px;font-weight: bold">选择工作室</p>
  <a-select
      show-search
      style="width: 100%"
      label-in-value
      :options="studioOptions.model"
      :value="curStudio.model"
      :not-found-content="'无数据返回'"
      @change="selectStudio">
  </a-select>

</template>

<script setup>
import gs from 'good-storage'
import {reactive, ref, watch} from "vue";
import SingleSelect from "@/components/SingleSelect.vue";
import {useSingleSelect} from "@/hooks/useSelect";
import axios from "@/utils/http";
import {useStore} from "vuex";
//根据login后获得的company_id和admin_type再次请求admins族的studios接口
let studioUrl = "admins/studios"
let props = defineProps(["studioCompanies", "adminId", "adminType"])
let store = useStore()
let emit = defineEmits(["selectedStudio"])

let admin_id = ref(null)
let admin_type = ref(null)
admin_id.value = props.adminId
admin_type.value = props.adminType

let studioCompanies = props.studioCompanies.map(company => {
  return {
    label: company.name,
    value: company.company_id
  }
})

let curCompany = reactive({
  model: {
    label: null,
    value: null,
  }
})
let studioOptions = reactive({
  model: []
})

const reqStudios = url => {
  axios.post(url, {
    admin_id:admin_id.value,
    admin_type:admin_type.value,
    company_id: curCompany.model.value
  }).then(res => {
    if (res.data.code === 10000){
      studioOptions.model = res.data.data.map(studio => {
        return {
          label: studio.studio_name,
          value: studio.studio_id
        }
      })
    }
  })
}

if (props.studioCompanies.length > 0){
  let _ = props.studioCompanies[0]
  curCompany.model.value = _.company_id
  curCompany.model.label = _.name
  reqStudios(studioUrl)
}

const selectCompany = v => {
  curCompany.model = {
    label: v.label,
    value: v.key
  }
  curStudio.model = {
    label: null,
    value: null
  }
  reqStudios(studioUrl)
}

let curStudio = reactive({
  model: {
    value: null,
    label: null
  }
})

const selectStudio = v => {
  curStudio.model = {
    label: v.label,
    value: v.key
  }
  store.commit("organ/setCurStudio", curStudio.model)
  gs.set("curStudio",curStudio.model)
  emit("selectedStudio", {studio_id: curStudio.model.value, company_id: curCompany.model.value})
}



</script>

<style scoped>

</style>
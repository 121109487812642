import base64 from "crypto-js/enc-base64url"
import utf8 from "crypto-js/enc-utf8"

export default {
    encode: function (str){
        var wordArray = utf8.parse(JSON.stringify(str))
        return base64.stringify(wordArray);
    },
    decode: function (o) {
        var parsedWordArray = base64.parse(o);
        var parsedStr = parsedWordArray.toString(utf8);
        return JSON.parse(parsedStr)
    }
}



import gs from "good-storage";

const state = {
    routeState: {
        name: "",
        path: "",
        key: ""
    },
    openKeys: null,
}

const getters = {
    routeState:(state) => {
        if (state.routeState.name === "" && state.routeState.path===""){
            let routeState = gs.get("routeState", {})
            if (JSON.stringify(routeState) === "{}") return false
            return routeState
        }
        return state.routeState
    },
    name: (state) => state.routeState.name,
    path: (state) => state.routeState.path,
    key: (state) =>state.routeState.key,
    openKeys: (state) => {
        if (state.openKeys){
            return state.openKeys
        } else {
            let routeState =  gs.get("routeState", {})
            let [temp] = routeState.key
            temp = [temp.split("-")[0]]
            return temp
        }
    }
}

const mutations = {
    setName(state, payload){
        state.routeState.name = payload
    },
    setPath(state, payload){
        state.routeState.path = payload
    },
    setKey(state, payload){
        state.routeState.key = payload
    },
    setOpenKeys(state, payload) {
        state.openKeys = payload
    }
}

const actions = {
    setRouteState({commit}, payload){
        gs.set("routeState", payload)
        commit("setName", payload.name)
        commit("setPath", payload.path)
        commit("setKey", payload.key)
    },
}

export default {
    namespaced: true,
    state, getters, actions, mutations
}
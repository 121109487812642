import {host} from "@/utils/params";

const axios = require("axios")
import {message} from 'ant-design-vue'
import {load} from "@/utils/loading";

axios.defaults.baseURL = host + "api/v1/back"
axios.__proto__.host = axios.defaults.baseURL.replace("api/v1/back", "")
//请求拦截器
axios.interceptors.request.use(
    function (config){
        // console.log(config.headers.Authorization)
        return config
    },
    function (error){
        return Promise.reject(error)
    }
)

//响应拦截器
axios.interceptors.response.use(res=>{
    //todo 2024.11.8 认为 前后端的错误码要优化一下
    //todo 优化具体思路：前端，统一在拦截器报错， 后端，统一用抛错的方式回复错误，正常的回复全部是success
    if (res.data.code === 50010) return res //优惠券详情页修改标签回复， 有50010和50009 所以才做的适配
    if (res.data.code > 10000 && res.data.code !== 50009){
        message.warn(res.data.msg)
    }

    return res
} ,err=>{
    switch (err.response.status) {
        case 401:
            message.error("登录过期")
            break
        case 402:  //后台只要在response->json(..., code)的code与这里相符，就会在这里显示 ；同时不会流到后面的$http的body里
            message.error(err.response.data.message || err.response.data.msg)
            break
        case 500:
            message.error(err.response.data.message || err.response.data.msg)
            break
        default:
            console.warn(err.response.data.message || err.response.data.msg)
    }
    load.hide()
    return Promise.reject(err)
})

export default axios

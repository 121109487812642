const state = {
    user_id: null,
    user_card_id: null,
    realname: "该用户未填姓名",
    activeKey: null,
    transFlag: false,

    //PersonalCardOrder
    refundInfoState: {
        data: [],
        type: 0,
    }
}

const getters = {
    user_id: (state)=>state.user_id,
    user_card_id: (state)=>state.user_card_id,
    realname: (state)=>state.realname,
    activeKey: (state)=>state.activeKey,
    transFlag: (state)=>state.transFlag,
    refundInfoState: (state) => state.refundInfoState
}

const mutations = {
    setUserId(state, user_id){
        state.user_id = user_id
    },
    setRealname(state, realname){
        state.realname = realname
    },
    setUserCardId(state, user_card_id){
        state.user_card_id = user_card_id
    },
    setActiveKey(state, activeKey){
        state.activeKey = activeKey
    },
    setTransFlag(state, flag){
        state.transFlag = flag
    },
    setRefundInfoState(state, refundInfoState){
        state.refundInfoState = refundInfoState
    }
}

const actions = {
    lookupCardAppoint({commit}, user_card_id){
        commit("setUserCardId", user_card_id)
        commit("setActiveKey", 2)
    }
}

export default {
    namespaced:true,
    state, getters, actions, mutations
}
import {createApp, reactive} from "vue";

import Loading from '@/components/Loading.vue'

const msg = reactive({
    show: false,
    title: "加载中……"
})

const $loading = createApp(Loading, {msg}).mount(document.createElement("div"))

const load = {
    show(title){ //控制显示的方法
        msg.show=true
        msg.title=title
        document.body.appendChild($loading.$el)
    },
    hide(){
        msg.show = false
    }
}

export {load}
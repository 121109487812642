import Base64 from "@/utils/Base64"

//解析token
export const parseToken = token =>{
        let origin = token.split(".")
        // let header = origin[0]
        let payload = origin[1]

        //2. 拿到payload
        payload = Base64.decode(payload)

        return payload
}

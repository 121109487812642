import gs from "good-storage";
import {TimeFormat} from "@/utils/time";
import dayjs from "dayjs";

export default class GS{
    static getGoodsidfromOnBindAdd(){
        return gs.get("goods_id_from_onBindAdd")
    }

    static setGoodsidfromOnBindAdd(goods_id){
        gs.set("goods_id_from_onBindAdd",goods_id)
    }

    static getToken(){
        return gs.get("token")
    }

    static setToken(token){
        gs.set("token", token)
    }

    static refreshWhenLogin(){
        gs.clear()
    }

    static setUserPage(page){
        gs.set("page_user_page", page)
    }

    static getUserPage(){
        return gs.get("page_user_page", 1)
    }

    static setSelectedDay(selected_day) {
        gs.set("selected_day", selected_day)
    }

    static getSelectedDay(){
        return gs.get("selected_day", dayjs().format(TimeFormat.FULL))
    }

    static setUserId(user_id){
        gs.set("user_id", user_id)
    }

    static getUserId(){
        return gs.get("user_id", 1)
    }

    static setStudioId(id){
        gs.set("studio_id",id)
    }
    static getStudioId(){
        return gs.get("studio_id")
    }
    static setCompanyId(id){
        gs.set("company_id",id)
    }
    static getCompanyId(){
        return gs.get("company_id")
    }

    static setUserOrderUserId(v){
        gs.set("user_order_user_id", v)
    }

    static getUserOrderUserId(){
        return gs.get("user_order_user_id")
    }

    static setUserPageCondition(condition){
        gs.set("user_page_condition", condition)
    }

    static getUserPageCondition(){
        return gs.get("user_page_condition")
    }
}
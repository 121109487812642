
const state = {
    pageCondition: {
        page: "",
        condition: {

        }
    }
}

const getters = {
    pageCondition: state => state.pageCondition
}

const mutations = {
    setPageCondition(state, payload){
        console.log("payload", payload)
        state.pageCondition = payload
    }
}

const actions = {

}

export default {
    namespaced:true,
    state, getters, actions, mutations
}
import dayjs from "dayjs";
import {TimeFormat} from "@/utils/time";

const state = {
    start_time: "",
    end_time: "",
    studio_id: "",
    lss_type: 0,
}

const getters = {
    start_time(state){ return state.start_time},
    end_time(state){ return state.end_time},
    studio_id(state){ return state.studio_id},
    lss_type(state){ return state.lss_type},

}

const mutations = {
    setStartTime(state, payload){
        state.start_time = payload
    },
    setEndTime(state, payload){
        state.end_time = payload
    },
    setStudioId(state, payload){
        state.studio_id = payload
    },
    setLssType(state, payload){
        state.lss_type = payload
    }
}

const actions = {
    setBeginEnd(store, payload){
        let start = dayjs(payload.start_time).format(TimeFormat.YMD)
        let end = dayjs(payload.end_time).format(TimeFormat.YMD)
        store.commit("setStartTime", start)
        store.commit("setEndTime", end)
    }
}

export default {
    namespaced: true,
    state, getters, actions, mutations
}

import gs from 'good-storage'
import {parseToken} from "@/utils/jwt";
import {message} from "ant-design-vue";
import dayjs from "dayjs";
import GS from "@/utils/gsSave";

const state = {
    loginFlag: 0,  //0未登录， 1已登录， 2注销
    adminInfo:{
        admin_name:"没有传名字",
    },
    adminType: 1, //0超管1公司2店铺
    expires: null,
    curStudio: {}
}

const getters = {
    adminName(state){ return state.adminInfo.admin_name},
    adminInfo(state){return state.adminInfo},
    loginFlag(state){return state.loginFlag},
    curStudio: (state) => {
        if (JSON.stringify(state.curStudio) !== "{}"){
            return state.curStudio
        } else {
            return gs.get("curStudio")
        }
    }
}

const mutations = {
    setAdminName:(state, payload) => {
        state.adminInfo.admin_name = payload
    },
    setAdminInfo:(state, payload) => {
        if(JSON.stringify(payload) !== "{}"){
            state.loginFlag = 1
            state.adminInfo = setAdminInfo(payload)
        } else {
            state.loginFlag = 0
            state.adminInfo = {
                admin_type: "",
                admin_name: ""
            }
        }
    },
    setLoginFlag:(state, payload) => {
        if (payload === 2) {
            return message.error("已登出")
        }
        if (payload=== 0) message.error("登录过期")
        state.loginFlag = payload
    },
    setCurStudio(state, payload){
        state.curStudio = payload
        gs.set("curStudio", payload)
    },
    setAdminType(state, payload){
        state.adminType = payload
    }
}

const actions = {
    dispatchAdminInfo:(store, token) => {
        let adminInfo = parseToken(token)
        GS.setToken(token)
        store.dispatch("checkAndUpdateLocalAdmin", adminInfo)
    },
    logout:(store, token ) =>{
       //清除缓存
        gs.set("__admin__", {})
        //改变登录状态
        store.commit("setAdminInfo", {})
        store.commit("setLoginFlag", 2)
        store.commit("setCurStudio", {})
    },
    checkExpires:(store, payload) => {
        let expires = payload.expires
        let now = +new Date()
        if (now >= expires || !expires){
            console.log("过期……或没有缓存。已清空用户缓存，请重新登录!")
            gs.set("__admin__", {})
            store.commit("setLoginFlag", 0)
            //清空__admin__，并跳转到登录页面
            return false
        } else {
            let time = dayjs(expires).format("HH:mm")
            message.info(`本次登录将在${time}失效`)
            store.commit("setAdminInfo", payload)
            store.commit("setLoginFlag", 1)
            return true
        }
    },
    checkAndUpdateLocalAdmin: async (store, payload) => {
        //首先检查是否过期
        if (!await store.dispatch("checkExpires", payload))
        {
            throw Error("令牌过期，请重新登录")
        }
        else {
            store.commit("setAdminInfo", payload)
        }
    }
}


const setAdminInfo = payload => {
    let admin = gs.get("__admin__", {})

    admin.admin_type = payload.admin_type
    admin.dict_ids = getIds(payload.dict_ids)
    admin.stable_ids = getStableIds(payload.stable_ids)
    admin.studio_id = payload.studio_id
    

    admin["admin_id"] = payload.admin_id || ""
    admin["company_id"] = payload.company_id || ""
    admin["admin_name"] = payload.admin_name
    admin["admin_type"] = payload.admin_type
    admin["admin_phone"] = payload.admin_phone
    admin["expires"] = payload.expires

    gs.set("__admin__", admin)
    return admin
}

const getIds = (dict_ids) => {
    //当登录时，传入的后台传来的[数字], 刷新页面时，由于登录时此处返回的对象在`adminInfo`中的 key也是`dict_ids`
    // 导致验证机制穿进来的是下面已返回的对象。导致2022-12-20总是丢失数据
    //所以要做一个验证。
    if (dict_ids instanceof Array){
        return {
            teacher_label_id :dict_ids[0],
            lesson_label_id:dict_ids[1],
            dance_type_id:dict_ids[2],
            lesson_level_id:dict_ids[3],
            discnt_type_id:dict_ids[4],
            goods_label_id:dict_ids[5],
            goods_type_id:dict_ids[6],
        }
    }else {
        return dict_ids
    }
}

const getStableIds = stable_ids => {
    if (stable_ids instanceof Array) {
        return {
            order_type_id: stable_ids[0],
            platform_id: stable_ids[1],
            user_label_id: stable_ids[2]
        }
    } else {
        return stable_ids
    }
}

export default {
    namespaced:true,
    state, getters, actions, mutations
}

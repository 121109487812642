const state = {
    orderGoodsIds: [],
    orderAcIds: []
}

const getters = {
    orderGoodsIds(state){ return state.orderGoodsIds},
    orderAcIds(state){ return state.orderAcIds},
}

const mutations = {
    setOrderGoodsIds(state, payload) {
        console.log({payload})
        state.orderGoodsIds = payload
    },
    setOrderAcIds(state, payload){
        state.orderAcIds = payload
    }
}

const actions = {

}

export default {
    namespaced: true,
    state, getters, actions, mutations
}
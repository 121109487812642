const state = {
    //地图位置
    recvPos: [],
    //singleSelect组件：用于填写默认值 或 显示的值
    selectObj: {
        label: "",
        value: null
    },
    //singleSelect组件：填写（后端返回的）搜索结果的对应label与value的键
    selectSearchKeyObj: {
        labelKey: "name",
        valueKey: "id"
    },
    selectCondition: {

    },
    //多选select
    multiSelectArr: {
        label: "",
        value: null
    },
    //singleSelect组件：填写（后端返回的）搜索结果的对应label与value的键
    multiSelectSearchKeyObj: {
        labelKey: "name",
        valueKey: "id"
    },
    multiSelectCondition: {

    }
}

const getters = {
    recvPos(state){ return state.recvPos},
    selectObj(state) { return state.selectObj},
    selectSearchKeyObj(state) { return state.selectSearchKeyObj}
}

const mutations = {
    /*******地图***********/
    setRecvPos(state, payload){
        state.recvPos = payload
    },
    /*******单选框***********/
    setSelectObj(state, payload){
        state.selectObj = payload
    },
    setSelectSearchKeyObj(state, payload){
        state.selectSearchKeyObj = payload
    },
    setSelectCond(state, payload){

    },
    /********** *************/

    /*******多选框***********/
    setMultiSelectArr(state, payload){
        state.selectObj = payload
    },
    setMultiSelectSearchKeyObj(state, payload){
        state.multiSelectSearchKeyObj = payload
    },
    setMultiSelectCond(state, payload){

    },
}

const actions = {
    /*******单选框***********/
    resetSelect(store){
        store.commit("setSelectObj", {
            label:"",
            value: null
        })
    },
    setSelectObj(store, payload){
        store.commit("setSelectObj", payload)
    },
    setSelectState(store, payload){
        //除了设置key还要设置条件
        store.commit("setSelectSearchKeyObj", payload.keyState)
        // store.commit("setSelectSearchKeyObj", payload.conditionState)
    },
    /********** *************/

    /*******多选框***********/
    resetMultiSelect(store){
        store.commit("setSelectObj", {
            label:"",
            value: null
        })
    },
    setMultiSelectArr(store, payload){
        store.commit("setMultiSelectArr", payload)
    },
    setMultiSelectState(store, payload){
        //除了设置key还要设置条件
        store.commit("setMultiSelectSearchKeyObj", payload.keyState)
        // store.commit("setSelectSearchKeyObj", payload.conditionState)
    },


}

export default {
    namespaced: true,
    state, getters, actions, mutations
}

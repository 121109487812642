import { createApp } from 'vue'
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/antd.css';
import {router} from './router'
import store from './store'
import * as antIcons from '@ant-design/icons-vue';
import {MapAk} from "@/utils/map";
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
import 'remixicon/fonts/remixicon.css'


let app = createApp(App)

//antdvue的icon注册
const icons = antIcons;
// 注册组件
Object.keys(icons).forEach(key => {
    app.component(key, icons[key])
})
// 添加到全局
app.config.globalProperties.$antIcons = antIcons
app.config.globalProperties.$getPdf = function (title, dom, emptyDomId) {
    // 注册getPdf方法，传入两个参数，此处使用了promise处理导出后的操作
    /*
    title: 导出文件名
    dom: 需要导出dom的id
     */
    return new Promise((resolve, reject) => {
        html2Canvas(dom, {
            useCORS: true, // 由于打印时，会访问dom上的一些图片等资源，解决跨域问题！！重要
            allowTaint: true // 允许跨域
        }).then(canvas => {
            const contentWidth = canvas.width
            const contentHeight = canvas.height
            // 根据A4纸的大小，计算出dom相应比例的尺寸
            const pageHeight = contentWidth / 592.28 * 841.89
            let leftHeight = contentHeight
            let position = 0
            const imgWidth = 595.28
            // 根据a4比例计算出需要分割的实际dom位置
            const imgHeight = 592.28 / contentWidth * contentHeight
            // canvas绘图生成image数据，1.0是质量参数
            const pageData = canvas.toDataURL('image/jpeg', 1.0)
            // a4大小
            const PDF = new JsPDF('', 'pt', 'a4')
            // 当内容达到a4纸的高度时，分割，将一整块画幅分割出一页页的a4大小，导出pdf
            if (leftHeight < pageHeight) {
                PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
            } else {
                while (leftHeight > 0) {
                    PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                    leftHeight -= pageHeight
                    position -= 841.89
                    if (leftHeight > 0) {
                        PDF.addPage()
                    }
                }
            }
            // 导出
            PDF.save(title + '.pdf')
            // 删除添加的空div

            const emptyDom = document.getElementById(emptyDomId)
            emptyDom.remove()
            resolve(true)
        })
            .catch(() => {
                reject(false)
            })
    })
}

app.use(router).use(store).use(Antd)
router.isReady().then(() => app.mount("#app"))

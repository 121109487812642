import axios from "@/utils/http";
import {message} from "ant-design-vue";
import gs from "good-storage";
import {picMap} from "@/utils/picHelper";
import dayjs from "dayjs";
import {TimeFormat} from "@/utils/time";

const state = {
    goodsv2: {},
    refreshFlag: false, //通过观察这个值来让goods页面刷新
    current: 0,
    addFlag: false,
    editFlag: false,
    curGoodsId: null,
    curGoods: {},  //230709建立， 目的是为了打开goodsBlock页面时，拿到goods的paitian_cnt
    avatar: [],
    banners: [],
    addModel: {
        goods_name: "",
        if_combine: false,
        desc: "",
        discnt_desc: "",
        content: "",
        remark: "",
        agree_status: 0, //联名卡同意情况
        list_label_ids: [],
        normal_label_ids: [],
        platform_ids: [],
        avatar: [],
        banners: [],
        thumb: {},
        descs: [],
        pay_type: 0,
        card_type_id: null,
        lss_type_id: null,
        banner_index: 1,
        buy_publish_discnt_ids: [],
        if_go_get: 0,
        desc_top: "",
        desc_bottom: "",
        desc_bottom_more: "",
        user_label_two_ids: [],
        auto_open_date: dayjs().format(TimeFormat.FULL),
        auto_open_day: 30
    },
    editModel: {

    },
    editModelClone: {

    },
    curCard: {
        brand_id: null,
        brand_name: null,
        company_id: null,
        company_name: null,
        studio_id: null,
        studio_name: null,
        card_id: null,
        card_name: null,
        union_price: null,
        stock: null,
        avatar: ""
    }
}

const getters = {
    ownerBrandId(state){ return state.ownerBrandId},
    refreshFlag(state){ return state.refreshFlag},
    addModel(state){ return state.addModel},
    editModel(state){ return state.editModel},
    editModelClone(state){ return state.editModelClone},
    curCard(state){ return state.curCard},
    avatar(state) {return state.avatar},
    banners(state) {return state.banners},
    current(state){ return state.current},
    addFlag(state){return state.addFlag},
    editFlag(state){return state.editFlag},
    curGoodsId(state){return state.curGoodsId},
    curGoods(state){return state.curGoods},
    goodsV2(state){return state.goodsv2},
}

const mutations = {
    toggleAdd(state){
        state.addFlag = !state.addFlag
    },
    toggleEdit(state){
        state.editFlag = !state.editFlag
    },
    setAddModelListLabelIds(state, ids){
        state.addModel.list_label_ids = ids
    },
    setEditModelListLabelIds(state, ids){
        state.editModel.list_label_ids = ids
        console.log("labels_after_edit", ids)
    },
    setAddModelNormalLabelIds(state, ids){
        state.addModel.normal_label_ids = ids
    },
    setEditModelNormalLabelIds(state, ids){
        state.editModel.normal_label_ids = ids
    },
    setAddModelPlatformIds(state, ids){
        state.addModel.platform_ids = ids
    },
    setEditModelPlatformIds(state, ids){
        state.editModel.platform_ids = ids
    },
    setAddModelAgreeIds(state, ids){
        state.addModel.agree_ids = ids
    },
    setEditModelAgreeIds(state, ids){
        state.editModel.agree_ids = ids
    },
    setAddModelTeachersIds(state, ids){
        state.addModel.teacher_ids = ids
    },
    setEditModelTeachersIds(state, ids){
        state.editModel.teacher_ids = ids
    },
    setAddModelGoodsLssTypeId(state, id){
        state.addModel.lss_type_id = id
    },
    setEditModelGoodsLssTypeId(state, id){
        state.editModel.lss_type_id = id
    },
    setAddModelGoodsCardTypeId(state, id){
        state.addModel.card_type_id = id
    },
    setEditModelGoodsCardTypeId(state, id){
        state.editModel.card_type_id = id
    },
    setAddModelDescs(state, data){
        state.addModel.descs = state.addModel.descs.concat(data)
    },
    setEditModelDescs(state, data){
        state.editModel.descs = state.editModel.descs.concat(data)
    },
    setAddModelThumb(state, data){
        state.addModel.thumb = data
    },
    setEditModelThumb(state, data){
        state.editModel.thumb = data
    },
    setAddModelShare(state, data){
        state.addModel.shares = data
    },
    setEditModelShare(state, data){
        state.editModel.shares = data
    },
    removeAddModeDescs(state, index){
        state.editModel.descs.splice(index, 1)
    },
    removeEditModeDescs(state, index){
        state.editModel.descs.splice(index, 1)
    },
    removeAddModeThumb(state){
        state.editModel.thumb = {}
    },
    removeEditModeThumb(state, index){
        state.editModel.thumb = {}
    },
    removeAddModelShare(state, index){
        state.editModel.shares = []
    },
    removeEditModelShare(state, index){
        state.editModel.shares = []
    },
    addOutCard(state, card){ //一般就是addCurCardToOutCards
        if (card instanceof Array){
            state.outCards.push(...card)
            state.outCards.push(...card)
        } else {
            state.outCards.push(Object.assign({}, card))
        }
    },
    resetOutCard(state, card){
        state.outCards = []
    },
    setCurCard(state, conObj){
        state.curCard = Object.assign(state.curCard, conObj)
    },
    setAddModel(state, payload){
        state.addModel = Object.assign(state.addModel, payload)
    },
    setEditModel(state, model){
        // let adminInfo = gs.get("__admin__")
        // let {company_id} = adminInfo
        let list_labels = model.labels.filter(v=>v.if_commend === 1)
        let normal_labels = model.labels.filter(v=>v.if_commend === 0)
        let list_label_ids = list_labels.map(v => v.dict_two_id)
        let normal_label_ids = normal_labels.map(v => v.dict_two_id)
        let user_label_ids = model.user_labels.map(ul => ul.user_label_two_id)

        // console.log(`list_label_ids`, list_label_ids)
        // console.log(`normal_label_ids`, normal_label_ids)

        state.editModel = {
            goods_id: model.goods_id,
            goods_name: model.goods_name,
            if_combine: model.if_combine,
            company_id: model.company_id,
            desc: model.desc || "",
            content: model.content || "",
            remark: model.remark || "",
            desc_top: model.desc_top,
            desc_bottom: model.desc_bottom,
            desc_bottom_more: model.desc_bottom_more,
            label_ids: [],
            list_label_ids,
            normal_label_ids,
            list_labels,
            normal_labels,
            if_go_get: model.if_go_get,
            platform_ids: model.platforms.map(v => v.dst_id),
            platforms: model.platforms.map(dictMapFunc),
            teacher_ids: model.teachers.map(teacher => teacher.teacher_id),
            teachers: model.teachers && model.teachers.map(teacher => {
                return {
                    label: teacher.name,
                    value: teacher.teacher_id
                }
            }) || [],
            card_type: model.card_type,
            card_type_id: model.card_type.card_type_id,
            lss_type: model.lss_type,
            lss_type_id: model.lss_type.lss_type_id,
            pay_type: model.pay_type,
            paitian_cnt: model.paitian_cnt,
            avatar: model.avatars,
            banners: model.banners,
            agree_arr: model.agree_arr,
            discnt_desc: model.discnt_desc,
            agree_ids: model.agree_arr.map(agree => {
                return agree.value
            }),
            banner_index: model.banner_index,
            discnt_labels: model.discnt_labels,
            discnt_label_ids: model.discnt_labels.map(discnt => discnt.discnt_id),
            //2023.5.18证明，不需要额外设置state.descs，直接把state.addModel.desc传到upload里就可以直接更新。
            descs: picMap(model.descs),
            thumb: picMap(model.thumb),
            shares: picMap(model.shares),
            publish_discnts: model.publish_discnts && model.publish_discnts.map(discnt => {
                return {
                    label: discnt.name,
                    value: discnt.discnt_id
                }
            }) || [],
            publish_discnt_ids: model.publish_discnts && model.publish_discnts.map(discnt => discnt.discnt_id) || [],
            user_label_ids,
            user_labels: model.user_labels,
            auto_open_date: model.auto_open_date,
            auto_open_day: model.auto_open_day,
        }
    },
    setEditModelClone(state, model){
        if (JSON.stringify(model) === "{}"){
            state.editModelClone = model
        } else {
            let list_labels = model.labels.filter(v=>v.if_commend === 1)
            let normal_labels = model.labels.filter(v=>v.if_commend === 0)
            let user_label_ids = model.user_labels.map(ul => ul.user_label_two_id)

            state.editModelClone = {
                goods_id: model.goods_id,
                goods_name: model.goods_name,
                if_combine: model.if_combine,
                lss_type_id: model.lss_type_id && model.lss_type.lss_type_id,
                card_type_id: model.card_type_id && model.card_type.card_type_id,
                desc: model.desc || "",
                content: model.content || "",
                remark: model.remark || "",
                desc_top: model.desc_top,
                desc_bottom: model.desc_bottom,
                desc_bottom_more: model.desc_bottom_more,
                labels: model.labels,
                label_ids: model.labels.map(v => v.dict_two_id),
                platform_ids: model.platforms.map(v => v.dict_two_id),
                avatar: model.avatars,
                banners: model.banners,
                if_go_get: model.if_go_get,
                discnt_labels: model.discnt_labels,
                discnt_label_ids: model.discnt_labels.map(discnt => discnt.discnt_id),
                descs: picMap(model.descs),
                thumb: picMap(model.thumb),
                shares: picMap(model.shares),
                paitian_cnt: model.paitian_cnt,
                agree_ids: model.agree_arr.map(v => v.value),
                teacher_ids: model.teachers && model.teachers.map(v => v.teacher_id) || [],
                publish_discnts_ids: model.publish_discnts && model.publish_discnts.map(discnt => discnt.discnt_id),
                publish_discnts: model.publish_discnts && model.publish_discnts.map(discnt => {
                    return {
                        label: discnt.name,
                        value: discnt.discnt_id
                    }
                }) || [],
                user_label_ids,
                auto_open_date: model.auto_open_date,
                auto_open_day: model.auto_open_day,
            }
        }
    },
    resetCurCard(state) {
        state.add = {
            brand_id: null,
            brand_name: null,
            company_id: null,
            company_name: null,
            studio_id: null,
            studio_name: null,
            card_id: null,
            card_name: null,
            union_price: null,
            stock: null
        }
    },
    setAddModelAgreeStatus(state){
        if (state.outCards.length === 0){
            //假如没有外卡，则说明不需要等待同意。。
            state.addModel.agree_status = 1
        }
    },
    setEditModelAgreeStatus(state){
        if (state.outCards.length === 0){
            //假如没有外卡，则说明不需要等待同意。。
            state.editModel.agree_status = 1
        } else {
            state.editModel.agree_status = 0
        }
    },
    setAddModelUserLabels(state, label_ids){
      state.addModel.user_label_two_ids = label_ids
    },
    setAddModelAutoOpenDate(state, v){
        state.addModel.auto_open_date = v
    },
    setEditModelAutoOpenDate(state, v){
        state.editModel.auto_open_date = v
    },
    setAddModelAutoOpenDay(state, v){
        state.addModel.auto_open_day = v
    },
    setEditModelAutoOpenDay(state, v){
        state.editModel.auto_open_day = v
    },
    setEditModelUserLabel(state, label_ids){
        state.editModel.user_label_two_ids = label_ids
    },
    //因后端每次返回的是[]，且里面只有一条，所以要包起来。
    setAvatar(state, avatar) {
        //前端删除一条照片。
        //但是不触发删除照片，而是最终createGoods/editGoods匹配时删除改变的照片
        //基本不主动删除temp文件夹，每天脚本来完成这件事。
        state.avatar = avatar
    },
    addBanners(state, bannerArr) {
        if (state.banners.length === 5){
            state.banners.shift()
        }
        state.banners = state.banners.concat(bannerArr)
    },
    setBanners(state, bannerArr) {
        state.banners = [...bannerArr]
    },
    setDiscntsAdd(state, discnt_ids){
        state.addModel.publish_discnt_ids = discnt_ids
    },
    setDiscntsEdit(state, discnt_ids){
        state.editModel.publish_discnt_ids = discnt_ids
    },
    setDiscntLabelsAdd(state, discnt_ids){
        state.addModel.discnt_label_ids = discnt_ids
    },
    setDiscntLabelsEdit(state, discnt_ids){
        state.editModel.discnt_label_ids = discnt_ids
    },
    removeABanner(state, index){
        state.banners.splice(index, 1)
    },
    setAddModelAvatar(state){
        state.addModel.avatar = state.avatar
    },
    setEditModelAvatar(state){
        state.editModel.avatar = state.avatar
    },
    setAddModelBanners(state){
        state.addModel.banners = state.banners
    },
    setEditModelBanners(state){
        state.editModel.banners = state.banners
    },
    bindAdminInfoToAddModel(state){
        let adminInfo = gs.get("__admin__")
        let curStudio = gs.get("curStudio")

        state.addModel = Object.assign(state.addModel, {
            ids: {
                studio_id: curStudio.value.value,
                company_id: adminInfo.company_id,
                brand_id: adminInfo.brand_id,
                admin_id: adminInfo.admin_id
            }
        })
    },
    needRefresh(state){
        state.refreshFlag = true
    },
    initRefresh(state){
        state.refreshFlag = false
    },
    addCurrent(state){
        state.current ++
    },
    minusCurrent(state){
        state.current --
    },
    resetCurrent(state){
        state.current = 0
    },
    resetAddModel(state){
        state.addModel = {
            goods_name: "",
            if_combine: false,
            desc: "",
            discnt_desc: "",
            content: "",
            remark: "",
            agree_status: 0, //联名卡同意情况
            list_label_ids: [],
            normal_label_ids: [],
            platform_ids: [],
            avatar: [],
            banners: [],
            thumb: {},
            descs: [],
            pay_type: 0,
            card_type_id: null,
            lss_type_id: null,
            banner_index: 1,
            buy_publish_discnt_ids: [],
            if_go_get: 0,
            desc_top: "",
            desc_bottom: "",
            desc_bottom_more: "",
            user_label_two_ids: [],
            auto_open_date: dayjs().format(TimeFormat.FULL)
        }
    },
    resetEditModel(state){
        state.editModel = {}
    },
    combineAddLabels(state){
        if (!state.addModel.list_label_ids) {
            state.addModel.label_ids = state.addModel.normal_label_ids
        }else if(!state.addModel.normal_label_ids){
            state.addModel.label_ids = []
        } else {
            state.addModel.label_ids = state.addModel.list_label_ids.concat(state.addModel.normal_label_ids)
        }
    },
    combineEditLabels(state){
        if (!state.addModel.list_label_ids) {
            state.editModel.label_ids = state.editModel.normal_label_ids
        }else if(!state.addModel.normal_label_ids){
            state.editModel.label_ids = []
        } else {
            state.editModel.label_ids = state.editModel.list_label_ids.concat(state.editModel.normal_label_ids)
        }

        //减轻传输流量
        state.editModel.list_labels = []
        state.editModel.normal_labels = []
    },
    setCurGoodsId(state, payload){
        state.curGoodsId = payload
    },
    setCurGoods(state, payload){
        state.curGoods = payload
    },
    setGoodsV2(state, goods){
        state.goodsv2 = goods
    }
}

const actions = {
    createGoods({commit}){
        commit("combineAddLabels")
        commit("setAddModelAvatar")
        commit("setAddModelBanners")
        commit("bindAdminInfoToAddModel")

        //2.请求后台
        axios.post("/trade/goods", state.addModel)
            .then(res => {
                if (res.data.code === 10000){
                    message.success(res.data.msg)
                    commit("toggleAdd")
                    commit("resetAddModel")
                    commit("setAvatar", [])
                    commit("setBanners", [])
                    commit("resetCurrent")
                    commit("needRefresh")
                }
                if (res.data.code === 40010){
                    message.error(res.data.msg)
                }
            })
    },
    updateGoods({commit}){
        commit("combineEditLabels")
        commit("setEditModelAvatar")
        commit("setEditModelBanners")

        state.editModel = deleteProp(state.editModel, ["labels", "platforms", "teachers"])
        state.editModelClone = deleteProp(state.editModelClone, ["labels", "platforms", "teachers", "discnts"])

        //2.请求后台
        axios.put("/trade/goods/"+state.editModel.goods_id, {
            newV:state.editModel,
            oldV:state.editModelClone,
        })
            .then(res => {
                if (res.data.code === 10000){
                    message.success(res.data.msg)
                    commit("toggleEdit")
                    commit("resetEditModel")
                    commit("setAvatar", [])
                    commit("setBanners", [])
                    commit("resetCurrent")
                    commit("setEditModelClone", {})
                    commit("needRefresh")
                }
                if (res.data.code === 40010){
                    message.error(res.data.msg)
                }
            })
    },
    toggleAdd({commit}){
        //会触发重置型操作。
        commit("toggleAdd")
        if (!state.addFlag){ //应该在关闭的时候清掉，否则关闭的时候不清，那么打开edit会污染avatar和banner，不能共用了。
            commit("resetAddModel")
            commit("setAvatar", [])
            commit("setBanners", [])
            commit("resetCurrent")
            // label和platforms长在addModel上就不reset了
        }
    },
    toggleEdit({commit}, model){
        //填充重置型操作
        commit("toggleEdit")
        //在goodsAdd.vue起来时
        if (!state.editFlag){
            commit("resetEditModel")
            commit("setAvatar", [])
            commit("setBanners", [])
            commit("resetCurrent")
            commit("setEditModelClone", {})
        } else {
            commit("setEditModel", model)
            commit("setEditModelClone", model)
            commit("setAvatar", model.avatars)
            commit("setBanners", model.banners)
        }
    },
}

const dictMapFunc = item => {
    if (item){
        return {
            label: item.value,
            value: item.dict_two_id
        }
    } else {
        return null
    }
}

const deleteProp = (obj, keys) => {
    for (const key of keys) {
        Reflect.deleteProperty(obj, key)
    }
    return obj
}

export default {
    namespaced: true,
    state, getters, actions, mutations
}

<template>
  <a-select
      show-search
      label-in-value
      :value="(selectObj.value||selectObj.value===0) ? selectObj: null"
      style="width: 100%"
      :filter-option="false"
      :not-found-content="fetching ? undefined : '该条件下无资源'"
      :options="data"
      :placeholder="placeholder"
      @search="fetch"
      @change="handleChange"
      @focus="fetchFocus"
      :disabled="disabled"
      allowClear
  >
    <template v-if="fetching" #notFoundContent>
      <a-spin size="small" />
    </template>
  </a-select>
</template>
<script>
import {computed, defineComponent, reactive, ref, toRefs, watch} from 'vue'
import {useStore} from 'vuex'
import {debounce, throttle} from 'lodash-es'
import axios from "@/utils/http"

export default defineComponent({
  props:{
    url: {
      type: String,
      default: ""
    },
    event: {
      type: String,
      default: ""
    },
    placeholder: {
      type: [String, null],
      default: "请选择"
    },
    selectObj: {
      type: Object,
      default: () => {
       return {
         label: null,
         value: null
       }
      }
    },
    selectSearchKeyObj: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    showDirect: {
      type: Boolean,
      default: () => false
    }
  },
  setup(props,{emit}) {
    let dis = ref(false)

    watch(() => props.disabled, () => {
      dis.value = props.disabled
    })

    let lastFetchId = 0;

    const state = reactive({
      data: [],
      value: {},
      fetching: false,
    });

    const fetchFocus = function (){
      if (!props.showDirect) return
      let condition = {}
      if (props.selectSearchKeyObj.condition){
        condition = Object.assign(condition, props.selectSearchKeyObj.condition)
      }
      axios.post(props.url, {condition})
          .then(res => {
            state.data = res.data.data.map(item => {
              return {
                label: item[props.selectSearchKeyObj.keyState.labelKey],
                value: item[props.selectSearchKeyObj.keyState.valueKey],
                [`${props.selectSearchKeyObj.keyState.other}`]:
                    item[`${props.selectSearchKeyObj.keyState.other}`]
              }
            });
            state.fetching = false;
          });
    }

    const fetch = debounce(value => {
      if (value === " ") return
      if (value === ""){
        return fetchFocus(value)
      }
      //不提出去与fetchFocus共用的原因：2022.12.19，当方法外部设置props.selectSearchKeyObj时，只触发一次赋值，只有放在方法里面才可以随父组件变化而变化
      let condition = {}
      if (props.selectSearchKeyObj.condition){
        condition = Object.assign(condition, props.selectSearchKeyObj.condition)
      }
      Reflect.set(condition, props.selectSearchKeyObj.keyState.labelKey, value)

      lastFetchId += 1;
      const fetchId = lastFetchId;
      state.data = [];
      state.fetching = true;
      axios.post(props.url, {condition})
          .then(res => {
            if (fetchId !== lastFetchId) return

            state.data = res.data.data.map(item => {
              return {
                label: item[props.selectSearchKeyObj.keyState.labelKey],
                value: item[props.selectSearchKeyObj.keyState.valueKey],
                [`${props.selectSearchKeyObj.keyState.other}`]:
                    item[`${props.selectSearchKeyObj.keyState.other}`]
              }
            });
            state.fetching = false;
          })
    }, 300);

    watch(state.value, () => {
      state.data = [];
      state.fetching = false
    });

    const handleChange = (v)  => {
      if (!v){
        emit(props.event, "")
      } else {
        emit(props.event, v)
      }
    }

    return {
      ...toRefs(state),
      fetch,
      handleChange,
      fetchFocus
    };
  },
});
</script>

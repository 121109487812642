import { createRouter, createWebHistory } from 'vue-router'
import gs from 'good-storage'
import {message} from "ant-design-vue"
import store from "../store"

const defineCode = (code, label) => {
    return {code, label}
}

const checkAdminType = (typesArray) => {
    let admin_type = gs.get("__admin__")
    if (!admin_type){
        return false
    }
    admin_type = admin_type.admin_type
    return typesArray.find(type => type.code === admin_type)
}

const AdminTitle = {
    ADMIN_BRAND:defineCode(0, "品牌级管理员"),
    ADMIN_COMPANY:defineCode(1, "公司级管理员"),
    ADMIN_STUDIO:defineCode(2, "店铺级管理员")
}
//路由数组
//似乎应该对routes做一个filter
const routes = [
    {
        //基本格式
        path: "/",
        name: "home",
        meta: {title: "首页", icon: "HomeOutlined", auths: []},
        redirect: "/month_lesson_sub",
        key: "sub1",
        children: [
            {
                path: "info",
                name: "info",
                key: "sub1-1",
                component: () =>import("@/views/Organ/CompanyMoney"),
                meta: {title: "经营概况", auths: [AdminTitle.ADMIN_BRAND, AdminTitle.ADMIN_COMPANY], show: true, key: "sub1-1",},
                beforeEnter(to, from, next){
                    if (checkAdminType([AdminTitle.ADMIN_BRAND, AdminTitle.ADMIN_COMPANY])){
                        next()
                    }
                    else {
                        auth()
                    }
                }
            },
            {
                path: "month_lesson_sub",
                name: "month_lesson_sub",
                key: "sub3-361",
                component: () => import("@/views/Management/Lesson/MonthLessonSub.vue"),
                meta: {title: "排课管理子级", show: false, key: "sub3-361",},
            },
        ]
    },
    {
        //基本格式
        path: "/organ",
        name: "organ",
        meta: {title: "组织管理", icon: "GoldFilled", show: true},
        key: "sub2",
        children: [
            {
                path: "company_money",
                name: "company_money",
                key: "sub2-21",
                component: () =>import("@/views/Organ/CompanyMoney"),
                meta: {title: "账本管理", auths: [AdminTitle.ADMIN_BRAND, AdminTitle.ADMIN_COMPANY], show: true, key: "sub2-21",},
                beforeEnter(to, from, next){
                    if (checkAdminType([AdminTitle.ADMIN_BRAND, AdminTitle.ADMIN_COMPANY])){
                        next()
                    }
                    else {
                        auth()
                    }
                }
            },
            {
                path: "company",
                name: "company",
                key: "sub2-22",
                component: () =>import("@/views/Organ/Company"),
                meta: {title: "公司管理", auths: [AdminTitle.ADMIN_BRAND, AdminTitle.ADMIN_COMPANY], show: true, key: "sub2-22",},
                beforeEnter(to, from, next){
                    if (checkAdminType([AdminTitle.ADMIN_BRAND, AdminTitle.ADMIN_COMPANY])){
                        next()
                    }
                    else {
                        // auth()
                    }
                }
            },
            {
                path: "studio",
                name: "studio",
                key: "sub2-23",
                component: () => import("@/views/Organ/Studio"),
                meta: {title: "工作室管理", auths: [AdminTitle.ADMIN_BRAND, AdminTitle.ADMIN_COMPANY, AdminTitle.ADMIN_STUDIO], show: true, key: "sub2-23",},
            },
            {
                path: "admin",
                name: "admin",
                key: "sub2-24",
                component: () => import("@/views/Organ/Admin"),
                meta: {title: "管理员管理", auths: [AdminTitle.ADMIN_BRAND, AdminTitle.ADMIN_COMPANY], show: true, key: "sub2-24"},
            }
        ]
    },
    {
      path: "/member",
      name: "member",
      meta: {title: "会员", icon: "GoldFilled"},
      key: "sub3",
      children: [
          {
              path: "user",
              name: "user",
              key: "sub3-31",
              component: () => import("@/views/Management/User"),
              meta: {title: "会员", show: true, key: "sub3-31"},
          },
          {
              path: "card_list",
              name: "card_list",
              key: "sub3-321",
              component: () =>import("@/views/Management/Personal/PersonalCard.vue"),
              meta: {title: "会员的卡管理", show: true, key: "sub3-321"},
              beforeEnter(to, from, next) {
                  //因为这个页面进入时要依靠vuex的user_id来请求其卡。而外部进入则需要全部的。所以去除。
                  store.commit("personal/setUserId", null)
                  gs.remove("user_id")
                  next()
              }
          },
          {
              path: "card",
              name: "card",
              key: "sub3-32",
              component: () =>import("@/views/Management/Card/Card.vue"),
              meta: {title: "卡管理", show: true, key: "sub3-32"},
          },
          {
              path: "card_item/:card_id/:card_name",
              name: "card_item",
              key: "sub3-313",
              component: () =>import("@/views/Management/CardItem.vue"),
              meta: {title: "卡的子选项", show: true, key: "sub3-313",},
          },
          {
              path: "card_lesson/:card_id/:card_name",
              name: "card_lesson",
              key: "sub3-312",
              component: () =>import("@/views/Management/CardLesson.vue"),
              meta: {title: "卡与课程绑定管理", show: true, key:"sub3-321"},
          },
      ]
    },
    {
        //基本格式
        path: "/manage",
        name: "manage",
        meta: {title: "排课", icon: "GoldFilled"},
        key: "sub4",
        children: [
            {
                path: "month_lesson",
                name: "month_lesson",
                key: "sub3-336",
                component: () => import("@/views/Management/Lesson/MonthLesson.vue"),
                meta: {title: "团课排课", show: true, key: "sub3-336",},
            },
            {
                path: "private_lss",
                name: "private_lss",
                key: "sub3-3362",
                component: () => import("@/views/Management/Lesson/PrivateLesson.vue"),
                meta: {title: "私教排课", show: true, key: "sub3-3362",},
            },
            {
                path: "personal/:user_id",
                name: "personal",
                key: "sub3-353",
                component: () => import("@/views/Management/Personal/Personal.vue"),
                meta: {title: "个人页面", show: true, key: "sub3-353",},
            },
            {
                path: "sub_agree/:agree_id",
                name: "sub_agree",
                key: "sub3-354",
                component: () => import("@/views/Management/SubAgree.vue"),
                meta: {title: "协议版本详情", show: true, key: "sub3-354",},
            },
            {
                path: "lesson",
                name: "lesson",
                key: "sub3-332",
                component: () =>import("@/views/Management/Lesson"),
                meta: {title: "课程管理", show: true, key: "sub3-332"},
            },
            {
                path: "appoint_rule",
                name: "appoint_rule",
                key: "sub3-355",
                component: () => import("@/views/Management/AppointRule.vue"),
                meta: {title: "约课规则管理", show: true, key: "sub3-355",},
            },
            {
                path: "teacher",
                name: "teacher",
                key: "sub3-333",
                component: () => import("@/views/Management/Teacher"),
                meta: {title: "师资管理", show: true, key: "sub3-333",},
            },
            {
                path: "clsroom",
                name: "clsroom",
                key: "sub3-334",
                component: () => import("@/views/Management/Clsroom"),
                meta: {title: "教室管理", show: true, key: "sub3-334",},
            },
            {
                path: "agree",
                name: "agree",
                key: "sub3-351",
                component: () => import("@/views/Management/Agree.vue"),
                meta: {title: "协议管理", show: true, key: "sub3-351",},
            },
            {
                path: "user_card_trans",
                name: "user_card_trans",
                key: "sub3-37",
                component: () => import("@/views/Management/UserCardTransRecord.vue"),
                meta: {title: "转卡记录", show: true, key: "sub3-337",},
            },
        ]
    },
    {
        //基本格式
        path: "/trade",
        name: "trade",
        meta: {title: "交易管理", icon: "GoldFilled", show: true},
        key: "sub5",
        children: [
            {
                path: "goods:page",
                name: "goods",
                key: "sub4-41",
                component: () =>import("@/views/Trade/Goods/Goods.vue"),
                meta: {title: "商品管理", show: true, key: "sub4-41",},
            },
            {
                path: "activity",
                name: "activity",
                key: "sub4-45",
                component: () =>import("@/views/Trade/Activity/Activity.vue"),
                meta: {title: "活动管理", show: true, key: "sub4-45",},
            },
            {
                path: "group",
                name: "group",
                key: "sub4-42",
                component: () =>import("@/views/Trade/Group/Group.vue"),
                meta: {title: "团购管理", show: true, key: "sub4-42",},
            },
            {
                path: "order",
                name: "order",
                key: "sub4-43",
                component: () =>import("@/views/Trade/Order/Order.vue"),
                meta: {title: "订单管理", show: true, key: "sub4-43",},
            },
            {
                path: "discnt",
                name: "discnt",
                key: "sub4-44",
                meta: {title: "优惠券管理", show: true, key: "sub4-44"},
                component: () =>import("@/views/Trade/Discnt/Discnt.vue"),
            },
            {
                path: "void_goods",
                name: "void_goods",
                key: "sub4-335",
                component: () => import("@/views/Management/VoidGoods.vue"),
                meta: {title: "虚拟商品管理", show: true, key: "sub4-335",},
            },
            {
                path: "sub_group/:group_id",
                name: "sub_group",
                key: "sub4-422",
                component: () =>import("@/views/Trade/Group/SubGroup.vue"),
                meta: {title: "成团情况", show: true, key: "sub4-422",},
            },
        ]
    },
    {
        //基本格式
        path: "/system",
        name: "system",
        meta: {title: "系统管理", icon: "GoldFilled"},
        key: "sub6",
        children: [
            {
                path: "dict",
                name: "dict",
                key: "sub5-51",
                component: () =>import("@/views/System/DictOne.vue"),
                meta: {title: "字典", show: true, key: "sub5-51"},
            }, {
                path: "user_label_one",
                name: "user_label_one",
                key: "sub5-521",
                component: () =>import("@/views/System/UserLabelOne.vue"),
                meta: {title: "用户标签", show: true, key: "sub5-521"},
            }, {
                path: "user_label_two/:user_label_one_id",
                name: "user_label_two",
                key: "sub5-522",
                component: () =>import("@/views/System/UserLabelTwo.vue"),
                meta: {title: "用户标签二级", show: true, key: "sub5-522"},
            },
        ]
    },
    {
        //基本格式
        path: "/control",
        name: "control",
        meta: {title: "控制", icon: "GoldFilled"},
        key: "sub7",
        children: [
            {
                path: "front_control",
                name: "front_control",
                key: "sub6-600",
                component: () => import("@/views/Control/FrontControl.vue"),
                meta: {title: "小程序三宫格", show: true, key: "sub6-600",},
            },
            {
                path: "window_control",
                name: "window_control",
                key: "sub6-601",
                component: () =>import("@/views/Control/WindowsControl/WindowControl.vue"),
                meta: {title: "小程序弹窗", show: true, key: "sub6-601",},
            },
            // {
            //     path: "discnt_list",
            //     name: "discnt_list",
            //     key: "sub6-602",
            //     component: () =>import("@/views/Control/DiscntControl/DiscntControl.vue"),
            //     meta: {title: "领券大厅", show: true, key: "sub6-602",},
            // },
            {
                path: "pic",
                name: "pic",
                key: "sub6-603",
                component: () =>import("@/views/Control/PicControl/PicControl.vue"),
                meta: {title: "常用图片管理", show: true, key: "sub6-603",},
            },
            {
                path: "special",
                name: "special",
                key: "sub6-604",
                component: () =>import("@/views/Control/Special/Special.vue"),
                meta: {title: "特殊活动管理", show: true, key: "sub6-604",},
            },
            {
                path: "visible_control",
                name: "visible_control",
                key: "sub6-605",
                component: () =>import("@/views/Control/VisibleControl/VisibleControl.vue"),
                meta: {title: "显隐控制", show: true, key: "sub6-605",},
            },
            {
                path: "popup_control",
                name: "popup_control",
                key: "sub6-606",
                component: () =>import("@/views/Control/Popup/PopupControl.vue"),
                meta: {title: "课统计弹窗", show: true, key: "sub6-606",},
            }
        ]
    },
    {
        //基本格式
        path: "/statistic",
        name: "statistic",
        meta: {title: "统计分析", icon: "GoldFilled"},
        key: "sub8",
        children: [
            {
                path: "hot_area",
                name: "hot_area",
                key: "sub7-700",
                component: () => import("@/views/Statistic/HotArea.vue"),
                meta: {title: "分布热力图", show: true, key: "sub7-700",},
            },
            {
                path: "trace_router",
                name: "trace_router",
                key: "sub7-710",
                component: () => import("@/views/Statistic/TraceRouter.vue"),
                meta: {title: "路由行为追踪", show: true, key: "sub7-710",},
            },
            {
                path: "trace_list",
                name: "trace_list",
                key: "sub7-711",
                component: () => import("@/views/Statistic/TraceList.vue"),
                meta: {title: "列表行为追踪", show: true, key: "sub7-711",},
            },
            {
                path: "trace_inside",
                name: "trace_inside",
                key: "sub7-712",
                component: () => import("@/views/Statistic/TraceInside.vue"),
                meta: {title: "页内追踪", show: true, key: "sub7-712",},
            },
            {
                path: "share_goods",
                name: "share_goods",
                key: "sub7-741",
                component: () => import("@/views/Statistic/Share/GoodsShare.vue"),
                meta: {title: "商品分享统计", show: true, key: "sub7-741",},
            },
            {
                path: "share_lss",
                name: "share_lss",
                key: "sub7-742",
                component: () => import("@/views/Statistic/Share/LssShare.vue"),
                meta: {title: "课程分享统计", show: true, key: "sub7-742",},
            },
            {
                path: "share_users",
                name: "share_users",
                key: "sub7-743",
                component: () => import("@/views/Statistic/Share/UserShare.vue"),
                meta: {title: "用户分享统计", show: true, key: "sub7-743",},
            },
        ]
    },
    {
        //基本格式
        path: "/pop",
        name: "pop",
        meta: {title: "占位", icon: "GoldFilled"},
        key: "sub9",
        children: [
            {
                path: "trace_router_index/:id",
                name: "trace_router_index",
                key: "sub7-730",
                component: () => import("@/views/Statistic/TraceRouterChart.vue"),
                meta: {title: "路由行为追踪（饼图）", show: true, key: "sub7-730",},
            },
            {
                path: "trace_list_index/:id",
                name: "trace_list_index",
                key: "sub7-731",
                component: () => import("@/views/Statistic/TraceListChart.vue"),
                meta: {title: "列表行为追踪2", show: true, key: "sub7-731",},
            },
            {
                path: "trace_inside_index/:id/:title",
                name: "trace_inside_index",
                key: "sub7-732",
                component: () => import("@/views/Statistic/TraceInsideChart.vue"),
                meta: {title: "页内追踪2", show: true, key: "sub7-732",},
            },{
                path: "dict_two/:dict_one_id",
                name: "dict_two",
                key: "sub5-52",
                component: () =>import("@/views/System/DictTwo.vue"),
                meta: {title: "字典详情", show: true, key: "sub5-55"},
            }, {
                path: "card_req",
                name: "card_req",
                key: "sub5-53",
                component: () =>import("@/views/System/CardReq.vue"),
                meta: {title: "联卡请求", show: true, key: "sub5-53"},
            }, {
                path: "change_pass",
                name: "change_pass",
                key: "sub5-54",
                component: () =>import("@/views/System/ChangePass.vue"),
                meta: {title: "更改密码", show: true, key: "sub5-54"},
            },{
                path: "rank_waterfall",
                name: "rank_waterfall",
                key: "sub5-55",
                component: () =>import("@/views/Trade/Goods/RankWaterfall.vue"),
                meta: {title: "瀑布排序", show: true, key: "sub5-55"},
            },  {
                path: "rank_gobuycard:card_type_id:name",
                name: "rank_gobuycard",
                key: "sub5-56",
                component: () =>import("@/views/Trade/Goods/RankGoBuyCard.vue"),
                meta: {title: "去购卡排序", show: true, key: "sub5-56"},
            }, {
                path: "rank_gobuycardtab",
                name: "rank_gobuycardtab",
                key: "sub5-57",
                component: () =>import("@/views/Trade/Goods/RankGoBuyCardTab.vue"),
                meta: {title: "去购卡TAB排序", show: true, key: "sub5-57"},
            }, {
                path: "card_detail:user_card_id",
                name: "card_detail",
                key: "sub8-101",
                component: () =>import("@/views/Management/Card/CardDetail.vue"),
                meta: {title: "会员卡详情", show: true, key: "sub8-101"},
            },{
                path: "create_goods",
                name: "create_goods",
                key: "sub8-1010",
                component: () =>import("@/views/v2/Trade/Goods/CreateGoods.vue"),
                meta: {title: "创建商品", show: true, key: "sub8-1010"},
            },{
                path: "goods_bind_studio:goods_id",
                name: "goods_bind_studio",
                key: "sub8-1011",
                component: () =>import("@/views/v2/Trade/Goods/GoodsBindStudio.vue"),
                meta: {title: "绑定工作室", show: true, key: "sub8-1011"},
            },{
                path: "goods_block:goods_id",
                name: "goods_block",
                key: "sub8-1012",
                component: () =>import("@/views/v2/Trade/Goods/GoodsBlocks.vue"),
                meta: {title: "区间设置", show: true, key: "sub8-1012"},
            },{
                path: "goods_price_stock:goods_id/:card_type_id",
                name: "goods_price_stock",
                key: "sub8-1013",
                component: () =>import("@/views/v2/Trade/Goods/GoodsPriceStock.vue"),
                meta: {title: "价格库存设置", show: true, key: "sub8-1013"},
            },{
                path: "update_goods:page",
                name: "update_goods",
                key: "sub8-1014",
                component: () =>import("@/views/v2/Trade/Goods/updateGoods.vue"),
                meta: {title: "修改商品", show: true, key: "sub8-1014"},
            },{
                path: "create_discnt",
                name: "create_discnt",
                key: "sub8-1015",
                component: () =>import("@/views/v2/Trade/Discnt/AddDiscnt.vue"),
                meta: {title: "优惠券设置", show: true, key: "sub8-1015"},
            },{
                path: "update_discnt:page",
                name: "update_discnt",
                key: "sub8-1016",
                component: () =>import("@/views/v2/Trade/Discnt/UpdateDiscnt.vue"),
                meta: {title: "优惠券设置", show: true, key: "sub8-1016"},
            },{
                path: "discnt_detail",
                name: "discnt_detail",
                key: "sub8-1017",
                component: () =>import("@/views/v2/Trade/Discnt/DiscntDetail.vue"),
                meta: {title: "优惠券设置", show: true, key: "sub8-1017"},
            },{
                path: "discnt_update_basic",
                name: "discnt_update_basic",
                key: "sub8-1018",
                component: () =>import("@/views/v2/Trade/Discnt/Update/UpdateBasicInfo.vue"),
                meta: {title: "修改优惠券", show: true, key: "sub8-1018"},
            },{
                path: "discnt_update_bind_studios",
                name: "discnt_update_bind_studios",
                key: "sub8-1019",
                component: () =>import("@/views/v2/Trade/Discnt/Update/UpdateBindStudio.vue"),
                meta: {title: "修改优惠券", show: true, key: "sub8-1019"},
            },{
                path: "discnt_distribute",
                name: "discnt_distribute",
                key: "sub8-1020",
                component: () =>import("@/views/v2/Trade/Discnt/AcuireDiscnt/AcquireDiscnt.vue"),
                meta: {title: "去领券", show: true, key: "sub8-1020"},
            },{
                path: "discnts_mass_distribute",
                name: "discnts_mass_distribute",
                key: "sub8-1021",
                component: () =>import("@/views/v2/Trade/Discnt/AcuireDiscnt/MassProducitionDiscnt.vue"),
                meta: {title: "群发优惠券", show: true, key: "sub8-1021"},
            },{
                path: "acquire_discnt_param",
                name: "acquire_discnt_param",
                key: "sub8-1022",
                component: () =>import("@/views/Control/params/AcquireDiscntParam.vue"),
                meta: {title: "领券大厅背景设置", show: true, key: "sub8-1022"},
            },
        ]
    }
]

export const expRoutes = () => {
    routes[4].children.pop() //成团情况
    routes[2].children.splice(3, 4) //card_lesson card_item month_lesson_sub personal sub_agree
    routes[3].children.splice(1, 3) //card_lesson card_item month_lesson_sub personal sub_agree
    routes[3].children.pop() //card_lesson card_item month_lesson_sub personal sub_agree
    routes[8].children = []
    routes[5].children.pop()
    return routes
}

//路由对象
export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes //上面的路由数组
})

const auth = () => {
    message.warn("没有被授权")
    router.push({
        name: "month_lesson_sub"
    })
}

router.beforeEach((to, from ,next) => {
    //如果获取不到to,一般是由于redict造成.
    //将redirect的路由child放到home下(使用redirect的项的children)即可.
    store.dispatch("bread/setRouteState", {
        name: to.meta.title,
        path: to.path,
        key: [to.meta.key]
    }).then(res => {
        console.log(`meta2`, gs.get("routeState"))
        next()
    })

})

export {
    AdminTitle
}